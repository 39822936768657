
import { LogoutComponent } from './logout/logout.component';

import { DialogRoute } from '../common';
import { AuthGuard, ForgotComponent, LoginBounceComponent, LoginComponent, LoginProviderComponent, NotLoggedInGuard } from '../user';

import { Route } from '@angular/router';
import joinHandlerGuard from '@tytapp/app/join-handler-guard';
import { OpenInMobileAppLinkHandlerComponent } from '@tytapp/app/open-in-mobile-app-link-handler/open-in-mobile-app-link-handler.component';
import { OfflinePageComponent, PageNotFoundComponent } from '@tytapp/common-ui';
import { HoldingPageComponent } from '@tytapp/common-ui/holding-page.component';
import { UserAuthMediatorComponent } from '@tytapp/user/user-auth-mediator/user-auth-mediator.component';
import { SignUpComponent } from '@tytapp/user';
import { STATIC_REDIRECTS, SlugComponent } from '../slugs';
import { FeedComponent } from './feed/feed.component';
import { FinishOauthSignupComponent } from './finish-oauth-signup/finish-oauth-signup.component';
import { mediaUrlMatcher } from './pages/watch-pages/media-url-matcher';
import { RerouteComponent } from './reroute.component';
import { RedirectionComponent, externalRedirect } from './redirection/redirection.component';

const reroute = (path: string, url: string) => <Route>{ path, component: RerouteComponent, data: { url } };

export const ROUTES: Route[] = [

    { path: '', component: SlugComponent, pathMatch: 'full' },
    { path: 'login/return', component: LoginBounceComponent },
    { path: 'sign-in-with/:provider', component: LoginProviderComponent },

    /* LAZY */

    { path: 'home', loadChildren: () => import('./pages/home-page').then(m => m.HomePageModule), pathMatch: 'full' },
    { path: 'engineering', loadChildren: () => import('./pages/tech-pages').then(m => m.TechPagesModule) },
    { path: 'reports/edit', loadChildren: () => import('./pages/article-editor-pages').then(m => m.ArticleEditorPagesModule) },
    { path: 'pages', loadChildren: () => import('./pages/content-pages').then(m => m.ContentPagesModule) },
    { path: 'reports', loadChildren: () => import('./pages/article-pages').then(m => m.ArticlePagesModule) },
    { path: 'stories', loadChildren: () => import('./pages/article-pages').then(m => m.ArticlePagesModule) },
    { path: 'polls', loadChildren: () => import('./pages/poll-pages').then(m => m.PollPagesModule) },
    { path: 'campaigns', loadChildren: () => import('./pages/petition-pages').then(m => m.PetitionPagesModule) },
    { path: 'petitions', pathMatch: 'prefix', redirectTo: 'campaigns' },
    { path: 'affiliate', pathMatch: 'prefix', redirectTo: '/' },
    { path: 'nation', redirectTo: 'community' },
    { path: 'community', pathMatch: 'full', redirectTo: '/home' },
    { path: 'community', loadChildren: () => import('./pages/community-pages').then(m => m.CommunityPagesModule) },
    { path: 'comments', loadChildren: () => import('./pages/embedded-comment').then(m => m.EmbeddedCommentModule) },
    { path: 'spot', loadChildren: () => import('./pages/spot-pages').then(m => m.SpotPagesModule) },
    { path: 'billing', loadChildren: () => import('./pages/billing-pages').then(m => m.BillingPagesModule) },
    { path: 'careers', loadChildren: () => import('./pages/career-pages').then(m => m.CareerPagesModule) },
    { path: 'topics', loadChildren: () => import('./pages/topic-pages').then(m => m.TopicPagesModule) },
    { path: 'podcasts', loadChildren: () => import('./pages/podcast-pages').then(m => m.PodcastPagesModule) },
    { path: 'watch', loadChildren: () => import('./pages/watch-pages').then(m => m.WatchPagesModule) },
    { matcher: mediaUrlMatcher, loadChildren: () => import('./pages/watch-pages').then(m => m.WatchPagesModule) },
    { path: 'listen', loadChildren: () => import('./pages/watch-pages').then(m => m.WatchPagesModule) },
    { path: 'impacts', loadChildren: () => import('./pages/impacts-pages').then(m => m.ImpactsPagesModule) },
    { path: 'archive', loadChildren: () => import('./pages/archive-pages').then(m => m.ArchivePagesModule) },
    { path: 'shows', loadChildren: () => import('./pages/show-pages').then(m => m.ShowPagesModule) },
    { path: 'live', loadChildren: () => import('./pages/live-pages').then(m => m.LivePagesModule) },
    { path: 'about', loadChildren: () => import('./pages/about-pages').then(m => m.AboutPagesModule) },
    { path: 'settings', loadChildren: () => import('./pages/settings-pages').then(m => m.SettingsPagesModule) },
    { path: 'accounts', loadChildren: () => import('./pages/accounts-pages').then(m => m.AccountsPagesModule) },
    { path: 'terms', loadChildren: () => import('./pages/terms-pages').then(m => m.TermsPagesModule) },
    { path: 'privacy', loadChildren: () => import('./pages/terms-pages').then(m => m.TermsPagesModule) },
    { path: 'values', loadChildren: () => import('./pages/terms-pages').then(m => m.TermsPagesModule) },
    { path: 'search', loadChildren: () => import('./pages/search-pages').then(m => m.SearchPagesModule) },
    { path: 'downloads', loadChildren: () => import('./pages/downloads-pages').then(m => m.DownloadsPagesModule ) },
    { path: 'm', children: [ { path: '**', component: OpenInMobileAppLinkHandlerComponent }] },

    // REROUTES

    reroute('', '/home'),
    reroute('redeem', '/billing/redeem'),
    reroute('nation', '/billing/nation'),
    reroute('how-to-watch', '/watch/how-to-watch'),
    { path: 'validate-email/:token', redirectTo: '/accounts/validate-email/:token' },
    reroute('validate-email', '/accounts/validate-email'),
    { path: 'payment-confirmation/:offerSetID/:offerID', redirectTo: '/' },

    // this is better handled as a redirect at Cloudflare to ensure GTM tagging etc
    {
      path: 'join',
      component: PageNotFoundComponent,
      data: { id: 'join' }
    },

    {
        path: 'join/membership',
        canActivate: [joinHandlerGuard],
        component: HoldingPageComponent
    },

    ////////////////////

    { path: 'account', redirectTo: '/settings/account' },
    { path: 'oauth/welcome', component: FinishOauthSignupComponent },
    { path: 'oauth/welcome/:step', component: FinishOauthSignupComponent },
    { path: 'feed', component: FeedComponent },
    { path: 'feed/:content', component: FeedComponent },

    /* SHOWS */

    { path: 'episodes/:id', redirectTo: 'shows/-/episodes/:id' },

    /* LIVE STREAMING */

    { path: 'memberslive', redirectTo: 'live/qc-members' },

    /* ABOUT */

    { path: 'honor-roll-old', redirectTo: 'about/honor-roll-old' },
    { path: 'faq', redirectTo: 'about/faq' },
    { path: 'faqs', redirectTo: 'about/faq' },
    { path: 'contact', redirectTo: 'about/contact' },
    { path: 'company', redirectTo: 'about/company' },
    { path: 'support', redirectTo: 'about/support' },

    /* LOGIN/LOGOUT */

    DialogRoute('login', UserAuthMediatorComponent, [], [ NotLoggedInGuard ]),
    DialogRoute('signin', LoginComponent, [], [ NotLoggedInGuard ]),
    DialogRoute('login/forgot', ForgotComponent, [], [NotLoggedInGuard ]),
    DialogRoute('login/forgot/:token', ForgotComponent, [':token'], [NotLoggedInGuard ]),

    { path: 'signup', component: SignUpComponent, canActivate: [NotLoggedInGuard] },
    { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },

    // Shell
    { path: 'offline', component: OfflinePageComponent },

    /* REDIRECTS */

    externalRedirect('help', 'https://help.tyt.com'),
    ...Object.entries(STATIC_REDIRECTS).map(([from, to]) => ({ path: from, pathMatch: 'full' as const, redirectTo: to })),

    /* SLUGS */

    { path: 'not-found', pathMatch: 'full', component: PageNotFoundComponent, data: { reuse: false } },

    ...SlugComponent.routes(),

    /* NOT FOUND */

    { path: '**', component: PageNotFoundComponent }
];

PageNotFoundComponent.routes = ROUTES;