import { Component, inject } from "@angular/core";
import { UserService } from "@tytapp/user";
import { Redirection, AppConfig, sleep, LoggerService } from "@tytapp/common";
import { ApiUserOmniauth, UsersApi, CommunicationPrefsApi, ApiUser } from "@tytapp/api";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { environment } from "@tytapp/environment";
import { isServerSide } from '@tytapp/environment-utils';

@Component({
    selector: 'tyt-finish-oauth',
    templateUrl: './finish-oauth-signup.component.html',
    styleUrls: ['./finish-oauth-signup.component.scss']
})
export class FinishOauthSignupComponent {
    private userService = inject(UserService);
    private redirection = inject(Redirection);
    private route = inject(ActivatedRoute);
    private userApi = inject(UsersApi);
    private communicationPrefsApi = inject(CommunicationPrefsApi);
    private logger = inject(LoggerService);

    destinationUrl = '/';
    ytcmMissing = false;
    step = null;
    user: ApiUser;
    emailAddress = '';
    emailOptin = true;
    stepsVisited: string[] = [];
    subsink = new SubSink();

    async ngOnInit() {
        await this.userService.ready;

        this.subsink.add(this.userService.userChanged.subscribe(user => {
            this.user = user;
            this.ytcmMissing = false;

            if (user) {
                let omniauths: ApiUserOmniauth[] = <any>user.omniauths;
                let youtubeOmniauth = omniauths.find(x => x.provider === 'youtube');
                let hasYouTubeMembership = user.membership && user.membership.gateway_name === 'youtube';
                let hasYouTube = !!youtubeOmniauth;

                this.ytcmMissing = !user.entitled && hasYouTube;
            }

            //this.ytcmMissing = true; // DEBUG
        }));

        this.route.queryParamMap.subscribe(query => {
            if (query.has('destination')) {
                this.destinationUrl = query.get('destination');
            } else {
                this.destinationUrl = '/';
            }
        });

        this.route.paramMap.subscribe(params => {

            if (params.has('step')) {
                this.step = params.get('step');

                if (this.step === 'ytcm') {
                    this.stepsVisited = ['email', 'email-opt-in'];
                }
                this.loading = false;
            } else {
                this.stepsVisited = [];
                this.step = null;
                this.selectNextStep();
            }
        });
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
    }

    addQueryParamsToUrl(url: string, params: Record<string, any>) {
        if (Object.keys(params).length === 0)
            return url;

        url = url.replace(/&$/, '').replace(/\?$/, '');
        let delimiter = url.includes('?') ? '&' : '?';
        let queryString = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

        return `${url}${delimiter}${queryString}`;
    }

    loading = true;
    errorMessage = null;
    loadingMessage = 'Just a second...';

    async saveEmail() {
        this.loadingMessage = 'Updating your email address...';
        this.loading = true;
        await sleep(1000);
        try {
            if (0) {
                throw new Error('Looks like something went wrong');
            }

            await this.userApi.updateUser({ email: this.emailAddress }).toPromise();
            this.user.email = this.emailAddress;
            this.userService.locallyUpdateUser(this.user);
        } catch (e) {
            if (e.json)
                e = e.json();

            let message = e.message || e.error;

            if (message.includes('already been taken')) {
                message = `
                    Looks like that email address is already in use.
                    If you already have a TYT account, you may wish
                    to login to that account first and then connect
                    your social accounts (YouTube, Google, Twitter, Facebook)
                    from your Account page. Once you've done that. you can
                    log in using your social accounts at any time!
                    If you'd like help with this, please contact us at support@tytnetwork.com`;
            }

            this.loading = false;
            this.errorMessage = message;
            return;
        }

        this.loading = true;
        this.userService.user.email = this.emailAddress;

        this.selectNextStep();
    }

    async saveOptin() {
        this.loadingMessage = 'Saving your preferences...';
        this.loading = true;

        await sleep(1000);
        try {
            await this.communicationPrefsApi.patchCommunicationPrefs({
                communication_prefs: [
                    <any>{
                        provider: 'email',
                        provider_id: 'newsletter',
                        allowed: this.emailOptin
                    }
                ]
            }).toPromise();
        } catch (e) {
            this.logger.error("Failed to save communication preference. Skipping.");
        }

        this.selectNextStep();
        this.loading = false;
    }

    hasCompletedStep(step: string) {
        return this.stepsVisited.includes(step);
    }

    connectWithProvider(provider: string) {
        if (isServerSide())
            return;

        let url = `${environment.urls.platform}/sign-in-with/${provider}?jwt=${encodeURIComponent(this.userService.token)}&return_url=${encodeURIComponent(`/oauth/welcome/ytcm`)}`;
        window.location.assign(url);
    }
    nicknameForConnectedAccount(account: ApiUserOmniauth) {
        if (account.provider === 'twitter') {
            return `${account.name} (@${account.nickname})`;
        }

        return `${account.name} [${account.nickname}]`;
    }

    get youtubeConnections() {
        let omniauths: ApiUserOmniauth[] = <any>this.user.omniauths;

        return omniauths.filter(x => x.provider === 'youtube');
    }

    selectNextStep() {
        let user = this.userService.user;
        let lastStep = this.step;

        if (lastStep) {
            this.stepsVisited.push(lastStep);
        }

        if (!user) {
            this.redirection.go(this.destinationUrl);
            return;
        }

        let omniauths: ApiUserOmniauth[] = <any>user.omniauths;
        let youtubeOmniauth = omniauths.find(x => x.provider === 'youtube');
        let hasYouTubeMembership = user.membership && user.membership.gateway_name === 'youtube';
        let hasYouTube = !!youtubeOmniauth;
        let needsEmail = !user.email || user.email.endsWith('@pages.plusgoogle.com');

        if (needsEmail) {
            this.step = 'email';
        } else if (!this.hasCompletedStep('email-opt-in')) {
            this.step = 'email-opt-in';
        } else if (!this.hasCompletedStep('ytcm') && hasYouTube) {
            this.step = 'ytcm';
        } else {
            this.redirection.go(this.addQueryParamsToUrl(this.destinationUrl, { onboarded: 1 }));
        }

        this.loading = false;
        this.errorMessage = null;
    }
}